<script>
import AuthorSearchSelect from '@/components/author/AuthorSearchSelect'
import CoreApi from '@/api/core'

export default {
  name: 'SourceSearchSelect',
  extends: AuthorSearchSelect,
  props: {
    selectTitle: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      title: this.selectTitle ?? this.$t('dam.source')
    }
  },
  methods: {
    // overriding from AuthorSearchSelect.vue
    getItemName (item) {
      if (!item) {
        return ''
      }
      const name = item.customText ? item.customText : item.name
      return name.trim()
    },
    // overriding from AuthorSearchSelect.vue
    async fetchItems (query) {
      // TODO CMS-2570 double check filter_custom
      const filterQuery = query ? `&filter_custom[anyName]=${query}` : ''
      return (await CoreApi().get(`/authorSource?limit=${this.limit}${filterQuery}`)).data.data
    }
  }
}
</script>
