<template>
  <div>
    <div class="progress m-t-10">
      <div
        class="progress-bar bg-info wow"
        :class="{ 'animated progress-animated': animated }"
        :style="`width: ${progress}%; height:6px;`"
           role="progressbar">
        <span class="sr-only">60% Complete</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progressbar',
  props: {
    progress: {
      type: Number,
      default: 50
    },
    animated: {
      type: Boolean,
      default: true
    }
  }
}
</script>
