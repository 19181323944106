<template>
  <div v-if="!failedUploadedFilesIgnored && failedUploadedFiles.length > 0" class="failed-files">
    <div
      v-if="alreadyExistingFiles.length > 0"
      class="alert alert-info"
    >
      {{ $t('dam.existingImage.warningAndInfo') }}
    </div>
    <div class="alert alert-warning">
      <span class="failed-files__label">
        {{ $t('media.failed_uploaded_files') }}
      </span>
      {{ failedUploadedFiles.map(file => file.name).join(', ') }}
    </div>
    <Checkbox
      :value="value"
      @input="$emit('input', $event)"
      :label="$t('media.try_upload_again')"
      id="tryUploadAgain"
    />
  </div>
</template>

<script>
import Checkbox from '@/components/form/Checkbox'

export default {
  name: 'FailedUploadInfoPanel',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    failedUploadedFilesIgnored: {
      type: Boolean,
      required: true
    },
    failedUploadedFiles: {
      type: Array,
      required: true
    },
    alreadyExistingFiles: {
      type: Array,
      required: true
    }
  },
  components: {
    Checkbox
  }
}
</script>

<style scoped lang="scss">
.failed-files {
  margin-bottom: 1rem;
  &__label {
    color: red;
  }
}
</style>
