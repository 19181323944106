<template>
  <div>
    <p>
      {{ $t('dam.existingImage.title') }}
    </p>
    <button
      type="button"
      class="btn btn-green m-b-10"
      @click="confirmMetadata"
      data-test="existing_image_button_yes"
    >
      {{ $t('dam.existingImage.yesButton') }}
    </button>
    <button
      type="button"
      class="btn btn-inverse m-b-10"
      @click="loadUploadedMetadata"
      data-test="existing_image_button_no"
    >
      {{ $t('dam.existingImage.noButton') }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'UploadExistingImagePanel',
  props: {
    uploadedFile: {
      type: Object,
      required: true
    }
  },
  methods: {
    confirmMetadata () {
      this.uploadedFile.metadataChosen = true
    },
    loadUploadedMetadata () {
      this.uploadedFile.metadataChosen = true
      this.uploadedFile.description = this.uploadedFile.uploadedImageAsset?.description
      this.uploadedFile.author = this.uploadedFile.uploadedImageAsset?.author
      this.uploadedFile.keywords = this.uploadedFile.uploadedImageAsset?.keywords
      this.uploadedFile.source = this.uploadedFile.uploadedImageAsset?.source
      this.uploadedFile.imageAltText = this.uploadedFile.uploadedImageAsset?.imageAltText
      this.uploadedFile.siteLocks = this.uploadedFile.uploadedImageAsset?.siteLocks
    }
  }
}
</script>
