export const SOURCE_EAGLE_IMAGE = 'eagle_image'
export const SOURCE_EAGLE_URL = 'eagle_url'
export const SOURCE_FTP = 'ftp'
export const SOURCE_DAM_IMAGE = 'dam_image'
export const SOURCE_DAM_PDF = 'dam_pdf'
export const SOURCE_DAM_URL = 'dam_url'
export const SOURCE_OTHER = 'OTHER'
export const SOURCE_MIGRATION_CONTENT = 'content_mig'
export const SOURCE_MIGRATION_DAM = 'dam_mig'

export const AVAILABLE_SOURCES = [
  {
    id: SOURCE_EAGLE_IMAGE,
    title: 'Article Image'
  },
  {
    id: SOURCE_EAGLE_URL,
    title: 'Eagle URL'
  },
  {
    id: SOURCE_FTP,
    title: 'Fotografi (FTP)'
  },
  {
    id: SOURCE_DAM_IMAGE,
    title: 'Dam image'
  },
  {
    id: SOURCE_DAM_URL,
    title: 'Dam URL'
  },
  {
    id: SOURCE_OTHER,
    title: 'Other'
  },
  {
    id: SOURCE_MIGRATION_CONTENT,
    title: 'Content migration'
  },
  {
    id: SOURCE_MIGRATION_DAM,
    title: 'DAM migration'
  }
]

export const AVAILABLE_PDF_SOURCES = [
  {
    id: SOURCE_FTP,
    title: 'FTP'
  },
  {
    id: SOURCE_DAM_PDF,
    title: 'Dam PDF upload'
  },
  {
    id: SOURCE_DAM_URL,
    title: 'Dam URL'
  },
  {
    id: SOURCE_OTHER,
    title: 'Other'
  }
]

export const AVAILABLE_SOURCE_MAP = [
  ...AVAILABLE_SOURCES,
  AVAILABLE_PDF_SOURCES.find(source => source.id === SOURCE_DAM_PDF)]
  .reduce((acc, source) => {
    acc[source.id] = source.title
    return acc
  }, {})
