<template>
  <div v-if="loading || uploading" class="m-t-10 m-b-20">
    <h5 class="text-center">{{ loadingProgressText }}</h5>
    <Progressbar :progress="loadingProgress" :animated="false" />
  </div>
</template>

<script>
import Progressbar from '@/components/Progressbar'

export default {
  name: 'UploadProgressbar',
  props: {
    filesToLoadLength: {
      type: Number,
      required: true
    },
    filesToUploadLength: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      uploading: false,
      loadingImageProcess: 0,
      loadingProcessInterval: null,
      loadingImageCount: 0
    }
  },
  components: {
    Progressbar
  },
  computed: {
    loadingProgress () {
      let progress
      if (this.uploading) {
        progress = Object.values(this.$store.getters['dam/uploadProgress']).reduce((acc, progress) => acc + progress, 0)
      } else {
        progress = this.loadingImageProcess
      }
      return Math.min(progress / this.loadingImageCount * 100, 99)
    },
    loadingProgressText () {
      const loadingText = this.uploading ? this.$t('modal.uploading') : this.$t('modal.reading')
      return `${loadingText} ${Math.round(this.loadingProgress)}%`
    }
  },
  methods: {
    async loadingStarted () {
      this.loadingImageCount = this.filesToLoadLength
      this.loadingImageProcess = 0.2
      this.loading = true
      this.loadingProcessInterval = setInterval(() => {
        if ((this.loadingImageProcess < Math.ceil(this.loadingImageProcess) - 0.1) ||
          (this.loadingImageProcess === Math.ceil(this.loadingImageProcess))) {
          this.loadingImageProcess += 0.1
        }
      }, 100)
      // animate the loading process for first 300ms as loading with 'canvas' will block updating DOM for each individual file
      await new Promise(resolve => setTimeout(resolve, 300))
    },
    increaseLoadingProcess () {
      if (Math.ceil(this.loadingImageProcess) === this.loadingImageProcess) {
        this.loadingImageProcess += 1
      } else {
        this.loadingImageProcess = Math.ceil(this.loadingImageProcess)
      }
    },
    loadingFinished () {
      if (this.loadingProcessInterval) {
        clearInterval(this.loadingProcessInterval)
      }
      this.loading = false
    },
    uploadStarted () {
      this.$store.commit('dam/clearUploadProgress')
      this.loadingImageProcess = 0
      this.loadingImageCount = this.filesToUploadLength
      this.uploading = true
      this.$emit('toggle-uploading', this.uploading)
    },
    increaseUploadingProcess () {
      this.loadingImageProcess += 1
    },
    uploadFinished () {
      this.uploading = false
      this.$emit('toggle-uploading', this.uploading)
    }

  }
}
</script>
