import CoreApi from '../../api/core'
import Config from '../../config/'
import DamApi from '../../api/dam'
import { SOURCE_EAGLE_URL } from '@/model/ValueObject/DamUploadSources'
import DamService from '@/services/dam/DamService'
import Store from '@/store'

/**
 * Images are uploaded to DAM by chunks of 5 images to prevent overloading of the server.
 */
const DAM_UPLOAD_CHUNK_SIZE = 5

const findOneBy = function (id, callback) {
  return CoreApi().get('/image/' + id)
    .then(response => callback(response.status, response.data))
    .catch(error => console.log(error))
}

const getMediaUrl = function (widenId, width = '130', height = '', position = 'c', quality = 74) {
  let size = width
  if (width !== '' && height !== '') {
    size += 'x' + height
  }
  size += 'px'
  return Config.mediaUrl + '/' + widenId + '/' + size + '/?position=' + position + '&quality=' + quality
}

const dataUriToFile = function (dataURI, fileName) {
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new File([ia], fileName, { type: mimeString })
}

/**
 * Convert DAM asset into core-cms widen media.
 */
const mapDamAssetToCoreMedia = (asset) => {
  let imageCaption = asset.description
  if (imageCaption === '') {
    imageCaption = asset.caption
  }
  if (imageCaption === '') {
    imageCaption = asset.headline
  }
  return {
    damMediaEmbed: {
      damId: asset.entityUuid,
      filename: null,
      cdnRelativePath: DamService.getCdnRelativePath(asset, { dynamic: true })
    },
    imageCaption,
    imageAltText: asset.imageAltText,
    imageAttribution: asset.author,
    imageSource: asset.source,
    hideCaption: false,
    exportWoodWing: false,
    size: asset.size,
    dimensions: {
      width: asset.width,
      height: asset.height
    }
  }
}

const convertToUploadedFile = ({ imageAsset, uploadedImageAsset, wasCreated, fileBase64, originalFile }) => {
  return {
    entityUuid: imageAsset.entityUuid,
    fileBase64,
    fileName: imageAsset.fileName,
    caption: imageAsset.caption,
    imageAltText: imageAsset.imageAltText,
    description: imageAsset.description,
    author: imageAsset.author,
    source: imageAsset.source,
    keywords: imageAsset.keywords,
    siteLocks: imageAsset.siteLocks,
    imagePath: DamService.getDamImagePathForAsset(imageAsset),
    size: imageAsset.size,
    width: imageAsset.width,
    height: imageAsset.height,
    hideCaption: false,
    auxiliaryCrops: imageAsset.auxiliaryCrops,
    // 'uploadedImageAsset' contains metadata of the uploaded image
    //   in case the image has already existed (wasCreated: false) in the database with modified metadata
    uploadedImageAsset,
    wasCreated,
    metadataChosen: false, // 'metadataChosen' will be changed to 'true' by a user action
    originalFile // mainly used in DamUploadModal.vue
  }
}

const transformUploadedFileToSelectedMedia = (file) => {
  const media = mapDamAssetToCoreMedia(file)
  media.keywords = file.keywords
  media.dateTimeOriginal = file.dateTimeOriginal
  media.fileName = file.fileName
  return media
}

const createMedia = async (media, newMediaUsageType) => {
  const cropRequests = (media.mediaRatioUsages ?? []).map(ratioUsage => {
    const { ratioX, ratioY } = Store.getters['media/getRatioById'](ratioUsage.ratio)
    return {
      cropCoordinates: {
        leftUpperX: ratioUsage.cropLeftUpperX,
        leftUpperY: ratioUsage.cropLeftUpperY,
        rightBottomX: ratioUsage.cropRightBottomX,
        rightBottomY: ratioUsage.cropRightBottomY
      },
      cropRatioWidth: +ratioX,
      cropRatioHeight: +ratioY,
      selectedByAuthor: ratioUsage.selectedByAuthor
    }
  })
  const payload = {
    damAssetUuid: media.damMediaEmbed.damId,
    imageCaption: media.imageCaption,
    imageAltText: media.imageAltText,
    imageAttribution: media.imageAttribution,
    imageSource: media.imageSource,
    hideCaption: Boolean(media.hideCaption),
    exportWoodWing: Boolean(media.exportWoodWing),
    sensitiveContent: Boolean(media.sensitiveContent),
    showAttribution: Boolean(media.showAttribution),
    mediaUsageType: newMediaUsageType ?? media.mediaUsageType,
    site: media.site,
    cropRequests
  }
  const response = await CoreApi().post('/ImageUsage', payload)
  return response.data
}

const getImageNameFromUrl = (url) => {
  return url.split('/').pop()
}

const createMediaFromUrl = async (url, returnBeforeCreate) => {
  return new Promise((resolve, reject) => {
    const { maxCropWidth, maxCropHeight } = Store.getters['media/imageSettings']
    const bodyData = {
      url,
      uploadSource: SOURCE_EAGLE_URL,
      imageName: getImageNameFromUrl(url),
      categoryCode: undefined,
      siteLocks: [],
      unlockAfterPublish: true,
      maxCropWidth,
      maxCropHeight
    }
    DamApi().post('/image/from-url', bodyData)
      .then(async (response) => {
        const { imageAsset, uploadedImageAsset, wasCreated } = response.data
        const file = convertToUploadedFile({
          imageAsset,
          uploadedImageAsset,
          wasCreated,
          fileBase64: await urlToBase64(url)
        })
        const media = mapDamAssetToCoreMedia(file)
        let widenMedia = media
        if (!returnBeforeCreate) {
          widenMedia = await createMedia(media)
        }
        resolve({
          data: widenMedia
        })
      })
      .catch(error => {
        console.error(error)
        reject(new Error(error))
      })
  })
}

const urlToBase64 = (url) => new Promise((resolve, reject) => {
  const xhRequest = new XMLHttpRequest()
  xhRequest.onload = function () {
    const reader = new FileReader()
    reader.readAsDataURL(xhRequest.response)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  }
  xhRequest.open('GET', url)
  xhRequest.responseType = 'blob'
  xhRequest.send()
})

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  findOneBy,
  getMediaUrl,
  dataUriToFile,
  mapDamAssetToCoreMedia,
  convertToUploadedFile,
  transformUploadedFileToSelectedMedia,
  createMedia,
  createMediaFromUrl,
  toBase64,
  urlToBase64,
  DAM_UPLOAD_CHUNK_SIZE
}
