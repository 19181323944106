<template>
  <div class="form-group">
    <div class="multiselect-title">
      {{ title }}
      <slot name="afterTitle" />
    </div>
    <MultiSelect
      ref="multiSelect"
      :value="items.find(item => getItemName(item) === selectedItemName)"
      @input="onInput"
      @blur="$emit('blur', $event)"
      :error="error"
      :options="items"
      :hide-selected="hideSelected"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="false"
      @open="onOpen"
      :internal-search="false"
      :loading="loading"
      @search-change="onSearchChange"
      :multiple="false"
      :limit="1"
      :options-limit="limit"
      :show-no-results="!allowCustomItems"
      :placeholder="null"
      :taggable="allowCustomItems"
      :tag-placeholder="$t('enter_manually')"
      @tag="addCustomItem"
      :disabled="disabled"
      :show-change-checkbox="showChangeCheckbox"
      @change-checked="$emit('change-checked', $event)"
      :custom-label="getItemName"
      track-by="id"
      :id="id"
    />
  </div>
</template>

<script>
import MultiSelect from '@/components/form/select/MultiSelect'
import CoreApi from '@/api/core'
import { mapGetters } from 'vuex'

export default {
  name: 'AuthorSearchSelect',
  components: {
    MultiSelect
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    allowCustomItems: {
      type: Boolean,
      default: true
    },
    searchOnEmptyQuery: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    showChangeCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: [],
      loading: false,
      limit: 100,
      title: `${this.$t('dam.author')} ${this.required ? '*' : ''}`
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    selectedItemName () {
      return this.value
    },
    customSelectedItem () {
      return this.createCustomItem(this.selectedItemName)
    }
  },
  methods: {
    createCustomItem (customName) {
      const name = customName?.trim() ?? ''
      return { id: name, name, customText: name }
    },
    getItemName (item) {
      if (!item) {
        return ''
      }
      const name = this.vlm ? (item.customText ? item.customText : `${item.name} ${item.originName}`) : item.name
      return name.trim()
    },
    setSearchText (text) {
      // this also triggers this.onSearchChange()
      this.$refs.multiSelect.setSearchText(text)
    },
    setCursorToEnd () {
      const searchInput = this.$refs.multiSelect.$refs.multiselectReference.$refs.search
      const end = searchInput.value.length
      searchInput.setSelectionRange(end, end)
    },
    addCustomItem (text) {
      const customItem = this.createCustomItem(text)
      this.items.push(customItem)
      this.$refs.multiSelect.onInput(customItem) // this also triggers this.onInput()
    },
    onInput (selectedItem) {
      const itemName = this.getItemName(selectedItem)
      this.items = selectedItem ? this.items.filter(item => this.getItemName(item) === itemName) : []
      this.$emit('input', itemName)
      this.$emit('selected-item', selectedItem)
    },
    onOpen () {
      if (this.selectedItemName || this.searchOnEmptyQuery) {
        // do not set :preserve-search="true" to the multiselect as we need the search-input to clear on not-confirmed searches
        this.setSearchText(this.selectedItemName)
        if (!this.selectedItemName && this.searchOnEmptyQuery) {
          this.onSearchChange('')
        }
        this.setCursorToEnd()
      }
    },
    async fetchItems (query) {
      const nmhQuery = `&filter_contains[name]=${query}`
      // TODO CMS-2570 double check vlmQuery
      const vlmQuery = `&filter_custom[anyName]=${query}&filter_eq[typePhotographer]=1`
      const filterQuery = query ? (this.vlm ? vlmQuery : nmhQuery) : ''
      return (await CoreApi().get(`/author?limit=${this.limit}${filterQuery}`)).data.data
    },
    async onSearchChange (query) {
      if (!this.searchOnEmptyQuery && (!query || query.length < 2)) {
        return
      }
      try {
        this.loading = true
        this.items = await this.fetchItems(query)
        if (this.selectedItemName && !this.items.find(item => this.getItemName(item) === this.selectedItemName)) {
          this.items.unshift(this.customSelectedItem)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    if (this.selectedItemName) {
      this.setSearchText(this.selectedItemName)
      this.items = [this.customSelectedItem]
    }
  }
}
</script>

<style scoped lang="scss">
.multiselect-title {
  @include font(400 14px "Roboto");
  color: #8A96AC;
  padding-top: rem(3px);
  padding-bottom: rem(4px);
}
</style>
