export const DAM_CATEGORY_DEFAULT = 9
export const DAM_CATEGORY_VAS_TV_PROGRAM = 83
export const DAM_CATEGORY_PLUS_JEDEN_DEN = 41
export const DAM_CATEGORY_POLOVNICTVO_A_RYBARSTVO = 42
export const DAM_CATEGORY_MAMINA = 43
export const DAM_CATEGORY_BREJK = 44
export const DAM_CATEGORY_DOBRE_JEDLO = 45
export const DAM_CATEGORY_ZAHRADKAR = 46
export const DAM_CATEGORY_ZDRAVIE = 47
export const DAM_CATEGORY_PEKNE_BYVANIE = 48
export const DAM_CATEGORY_EMMA = 49
export const DAM_CATEGORY_SARM = 50
export const DAM_CATEGORY_BAJECNA_ZENA = 51
export const DAM_CATEGORY_PLUS_7_DNI = 52
export const DAM_CATEGORY_BOBIK = 58
export const DAM_CATEGORY_OHNIK = 59
export const DAM_CATEGORY_VCIELKA = 60
export const DAM_CATEGORY_ZORNICKA = 61
export const DAM_CATEGORY_MALOVANKLY = 62
export const DAM_CATEGORY_PESPLUS = 63
export const DAM_CATEGORY_PLUS_JEDEN_DEN_MAGAZIN = 64
export const DAM_CATEGORY_NENAVARENE_NAPECENE = 65
export const DAM_CATEGORY_PLUS_JEDEN_KRIZOVKY = 72
export const DAM_CATEGORY_PLUS_JEDEN_RECEPTAR = 73
export const DAM_CATEGORY_ZAZRACNY_SVET = 75
export const DAM_CATEGORY_PLUS_JEDEN_SPORT_AUTO = 76
export const DAM_CATEGORY_MOBILE = 77
export const DAM_CATEGORY_TREND = 81

export default {
  computed: {
    damCategories () {
      return [
        {
          id: DAM_CATEGORY_DEFAULT,
          title: 'Default PDF'
        },
        {
          id: DAM_CATEGORY_VAS_TV_PROGRAM,
          title: 'Váš TV Program'
        },
        {
          id: DAM_CATEGORY_PLUS_JEDEN_DEN,
          title: 'Plus Jeden Deň'
        },
        {
          id: DAM_CATEGORY_POLOVNICTVO_A_RYBARSTVO,
          title: 'Poľovníctvo a rybárstvo'
        },
        {
          id: DAM_CATEGORY_MAMINA,
          title: 'Mamina'
        },
        {
          id: DAM_CATEGORY_BREJK,
          title: 'Brejk'
        },
        {
          id: DAM_CATEGORY_DOBRE_JEDLO,
          title: 'Dobré jedlo'
        },
        {
          id: DAM_CATEGORY_ZAHRADKAR,
          title: 'Záhradkár'
        },
        {
          id: DAM_CATEGORY_ZDRAVIE,
          title: 'Zdravie'
        },
        {
          id: DAM_CATEGORY_PEKNE_BYVANIE,
          title: 'Pekné Bývanie'
        },
        {
          id: DAM_CATEGORY_EMMA,
          title: 'Emma'
        },
        {
          id: DAM_CATEGORY_SARM,
          title: 'Šarm'
        },
        {
          id: DAM_CATEGORY_BAJECNA_ZENA,
          title: 'Báječná žena'
        },
        {
          id: DAM_CATEGORY_PLUS_7_DNI,
          title: 'Plus 7 dni'
        },
        {
          id: DAM_CATEGORY_BOBIK,
          title: 'Bobík'
        },
        {
          id: DAM_CATEGORY_OHNIK,
          title: 'Ohník'
        },
        {
          id: DAM_CATEGORY_VCIELKA,
          title: 'Včielka'
        },
        {
          id: DAM_CATEGORY_ZORNICKA,
          title: 'Zornička'
        },
        {
          id: DAM_CATEGORY_MALOVANKLY,
          title: 'Maľovánky'
        },
        {
          id: DAM_CATEGORY_PESPLUS,
          title: 'PesPLUS'
        },
        {
          id: DAM_CATEGORY_PLUS_JEDEN_DEN_MAGAZIN,
          title: 'Plus Jeden Deň magazín'
        },
        {
          id: DAM_CATEGORY_NENAVARENE_NAPECENE,
          title: 'Nenavarené Napečené'
        },
        {
          id: DAM_CATEGORY_PLUS_JEDEN_KRIZOVKY,
          title: 'PLUS Jeden kižovky'
        },
        {
          id: DAM_CATEGORY_PLUS_JEDEN_RECEPTAR,
          title: 'PLUS Jeden receptar'
        },
        {
          id: DAM_CATEGORY_ZAZRACNY_SVET,
          title: 'Zázračný svet'
        },
        {
          id: DAM_CATEGORY_PLUS_JEDEN_SPORT_AUTO,
          title: 'Plus Jeden Šport auto'
        },
        {
          id: DAM_CATEGORY_MOBILE,
          title: 'Mobile'
        },
        {
          id: DAM_CATEGORY_TREND,
          title: 'Trend'
        }
      ]
    }
  }
}
