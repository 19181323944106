<template>
    <span>
        <button
          class="btn btn-info dam-upload-btn"
          @click="openFileDialog()"
          id="mediaUploadButton"
          data-test="upload_image_btn"
        >
            <i class="fas fa-upload"></i>
            {{ $t('buttons.upload_from_computer') }}
        </button>
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="readSelectedFiles"
          :multiple="multiple"
          :accept="fileTypes"
          data-test="image_upload"
        />
        <app-dam-upload-modal
          ref="uploadModal"
          v-if="uploadModal"
          :files="files"
          :upload-source="uploadSource"
          @close="closeModal"
          @set-media="setMedia"
        >
        </app-dam-upload-modal>
    </span>
</template>

<script>
import DamUploadModal from '../dam/damUploadModal/DamUploadModal'
import { SOURCE_DAM_IMAGE } from '@/model/ValueObject/DamUploadSources'
import { mapGetters } from 'vuex'

export default {
  name: 'DamUploadButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      files: [],
      uploadModal: false,
      uploadSource: SOURCE_DAM_IMAGE
    }
  },
  components: {
    appDamUploadModal: DamUploadModal
  },
  computed: {
    ...mapGetters('dam', ['isPdf']),
    fileTypes () {
      return this.isPdf ? 'application/pdf, application/x-pdf' : 'image/png, image/jpeg'
    }
  },
  methods: {
    openFileDialog () {
      // Clear input file value, prevent to open same file
      this.$refs.fileInput.value = ''
      this.$refs.fileInput.click()
    },
    async readSelectedFiles (event) {
      this.files = Object.values(event.target.files)
      this.showModal()
    },
    showModal () {
      this.uploadModal = true
    },
    closeModal () {
      this.uploadModal = false
    },
    setMedia (assets) {
      this.$emit('set-media', assets)
    }
  }
}
</script>
