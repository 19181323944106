import { render, staticRenderFns } from "./AuthorSearchSelect.vue?vue&type=template&id=0cbfc0f8&scoped=true&"
import script from "./AuthorSearchSelect.vue?vue&type=script&lang=js&"
export * from "./AuthorSearchSelect.vue?vue&type=script&lang=js&"
import style0 from "./AuthorSearchSelect.vue?vue&type=style&index=0&id=0cbfc0f8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cbfc0f8",
  null
  
)

export default component.exports